<template>
  <div class="out" :class="$i18n.locale == 'en' ? 'en_style' : ''">
    <div class="header">
      <div class="logo Title">
        <div class="Chinese">{{ $t('main.holdertran') }}</div>
      </div>
      <div class="right-icon">
        <img src="../assets/img/logo-icon.png" alt />
        <div class="cc-wc">{{ $t('main.titleCenter') }}</div>
      </div>
    </div>
    <div class="body">
      <!-- <div class="zb-top">
                <img :src="goods.pictures.length ? goods.pictures[0] + '?width=350' : ''" alt="">
                <div class="info">
                    <div class="">
                        <span>{{ $t('main.trname') }}：</span>
                        <span>{{ goods.name }}</span>
                    </div>
                    <div>
                        <span>{{ $t('main.gwtc') }}</span>
                        <span>{{ goods.serial_number }}</span>
                    </div>
                    <div>
                        <span>{{ $t('main.trorvalue') }}</span>
                        <span>￥{{ format_with_regex(goods.price) }}</span>
                    </div>
                </div>
      </div>-->
      <!-- <div class="list_item" style="font-size:0.18rem"  v-show="!$route.query.id">
                <span>{{$t('main.trname')}}：</span>
                <span style="color:#3662EC">{{goods.name}}</span>
            </div>
            <div class="list_item">
                {{$t('main.afthc')}}
      </div>-->
      <div class="pic">
        <img :src="goods.pictures[0]" alt />
      </div>
      <div class="zb-edit">
        <div class="zb_info">
          <div class="zb_item">
            <img src="../assets/img/mc.png" alt />
            <div>文创品名称：</div>
            <div>{{goods.name}}</div>
          </div>
          <div class="zb_item">
            <img src="../assets/img/bm.png" alt />
            <div>长城文创编码：</div>
            <div>{{goods.serial_number}}</div>
          </div>
          <div class="zb_item">
            <img src="../assets/img/yd.png" alt />
            <div>原珍宝名称：</div>
            <div>{{goods.goods.name}}</div>
            <!-- <div class="goPar" @click="goPar">进入</div> -->
          </div>
          <div class="zb_item">
            <img src="../assets/img/zz.png" alt />
            <div>原珍宝作者：</div>
            <div>{{goods.artist.name}}</div>
          </div>
        </div>
        <div class="zb_title">
          <img src="../assets/img/zy_t_icon.png" />
          申请获得珍宝持有者认证
        </div>
        <div class="zb_text" v-show="!goods.collector">输入文创品验证码：</div>
        <div class="zb_input" v-show="!goods.collector">
          <input type="text" v-model="verCode" />
        </div>
        <!-- <div class="zb_text">{{ $t('main.holderinshow') }}</div>
                <div class="zb_main">
                    <div class="flex check_box" @click="changeCustom(false)">
                       
                        <input type="radio" name="test" :checked="!is_custom">
                        <label class="list_input">
                            <div>{{ user.username }}</div>
                        </label>
                    </div>
                    <div class="flex check_box" @click="changeCustom(true)">
                        <input type="radio" name="test" :checked="is_custom">
                        <label class="list_input">
                            <input type="text" :placeholder="$t('main.userdefin')" v-model="custom_name">
                        </label>
                    </div>
                    <div class="zb_tips">
                        {{ $t('main.notehold') }}
                    </div>
        </div>-->
        <!-- v-show="$route.query.pay === 1" -->
        <!-- <div v-show="!$route.query.id && $route.query.pay == 1">
                    <div style="font-size:0.15rem;color:#707070;margin:0.2rem 0;">转让存证费用：
                        <span style="color:#fe0000">￥{{ goods.level.auth_fee }}</span>
                    </div>
                    <div class="zb_tips">注：提交认证需要您支付该费用，以永久储存转让信息并生成对 应的凭证证书，若对方最终未确认转让，系统将自动退还该费用。</div>
        </div>-->
        <!-- {{ $route.query.pay == 1 ? "支付并认证" : $t('main.submit') + $t('main.rz')}} -->
        <div v-show="goods.collector" class="isSuccess">该文创品已成功转让</div>
        <div class="upload" v-show="!goods.collector" @click="upload">下一步</div>
      </div>
    </div>

    <div class="modal" v-show="isChange">
      <div class="info-data">
        <div>持有者信息</div>
        <div>请选择持有者信息显示</div>
        <div @click="changeCustom(false)">
          <div :class="is_custom ? '':'active'"></div>
          <div>{{user.username}}</div>
        </div>
        <div @click="changeCustom(true)">
          <div :class="is_custom ? 'active':''"></div>
          <input type="text" v-model="custom_name" placeholder="自定义" />
        </div>
        <div>注：持有者信息显示自定义需经过后台审核； 审核通过后会立即显示在珍宝信息中</div>
        <div @click="uploadInfo">确认并提交认证</div>
      </div>
    </div>
    <div class="modal" v-show="isError">
      <div class="no-data">
        <div>{{msg}}</div>
        <div @click="closeModal">确定</div>
      </div>
    </div>
    <div class="pop pop_mask" v-show="shouquan" @click="shouquan = false"></div>
    <privacy ref="privacy" :more="'&id='+this.$route.query.id +( $route.query.qrCode?`&qrCode=${$route.query.qrCode}`:'')"></privacy>
  </div>
</template>

<script>
import { Dialog } from "vant";
import privacy from "../components/privacy.vue";
export default {
  components: {
    privacy
  },
  data() {
    return {
      goods: {
        name: "",
        serial_number: "",
        price: "",
        id: "",
        pictures: [],
        level: {
          auth_fee: ""
        },
        goods: {
          name: "",
          id: ""
        },
        artist: {
          name: ""
        },
        collector: null
      },
      user: {
        id: 0,
        username: "",
        avatar: ""
      },
      is_custom: false,
      custom_name: "",
      shouquan: false,
      isKnow: true,
      xy: {
        title: "",
        content: ""
      },
      is_xy_show: false,
      isToken: false,
      verCode: "",
      msg: "",
      isError: false,
      isChange: false,
      callback:()=>{
        this.isError = false
      }
    };
  },
  mounted() {
    let querys = "";
    if (this.$route.query.phone) {
      let phone = this.$route.query.phone;
      let nickName = this.$route.query.phone_code;
      querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`;
    }
    
    // http://192.168.1.65:8080/api/auth/login?url=http://192.168.1.65:8080/zr_cul?id=1
    this.axios(
      "collectionder/apply?goods_derivative_id=" +
        this.$route.query.id +
        "&code=" +
        this.$route.query.code +
        querys
    ).then(res => {
      if (res.data.code != 0) {
        if (res.data.msg == "请填写手机号") {
          this.$refs.privacy.openPopup();
          return;
        } else {
          console.log(111)
          this.msg = '该二维码已被使用，请返回重新获取最新二维码';
          this.isError = true;
          return
        }
      }
      this.goods = res.data.data.goods_derivative;
      this.user = res.data.data.user;
    });
  },
  methods: {
    closeModal(){
        return this.callback()
    },
    goPar() {
      this.$router.push("/detail/" + this.goods.goods.id);
    },
    async upload() {
      if (this.goods.collector) {
        this.isChange = true;
        return;
      }
      if (!this.verCode) {
        this.msg = "请输入文创品验证码";
        this.isError = true;
      } else {
        this.axios
          .post("collectionder/checkVerCode", {
            user_id: this.user.id,
            goods_derivative_id: this.goods.id,
            verCode: this.verCode
          })
          .then(res => {
            if (res.data.code != 0) {
              this.msg = res.data.msg;
              this.isError = true;
            } else {
              this.isChange = true;
            }
          });
      }
    },
    async uploadInfo() {
      let params = {
        user_id: this.user.id,
        goods_derivative_id: this.goods.id,
        name: this.user.username,
        avatar: this.user.avatar
          ? this.user.avatar
          : "http://t.6a.com/87/011930525c7d7011.png",
        is_custom: this.is_custom ? 1 : 0,
        verCode: this.verCode
      };
      if (this.is_custom) {
        if (!this.custom_name) {
          this.msg = this.$t("main.holderinoe");
          this.isError = true;
          return;
        }
        params.name = this.custom_name;
      }
      if (this.$route.query.qrCode) {
        params.qrCode = this.$route.query.qrCode;
      }
      if (this.goods.collector) {
        delete params.verCode;
      }
      this.axios.post("collectionder/apply", params).then(res => {
        if (res.data.code != 0) {
          this.msg = res.data.msg;
          this.isError = true;
          return;
        }
        this.isChange = false;
        this.msg = "认证成功";
        this.isError = true;
        this.callback = ()=>{
          this.$router.replace('/zbbox')
        }
      });
    },
    format_with_regex(number) {
      return !(number + "").includes(".")
        ? // 就是说1-3位后面一定要匹配3位
          (number + "").replace(/\d{1,3}(?=(\d{3})+$)/g, match => {
            return match + ",";
          })
        : (number + "").replace(/\d{1,3}(?=(\d{3})+(\.))/g, match => {
            return match + ",";
          });
    },
    changeCustom(bool) {
      this.is_custom = bool;
    },
    checkedFlag1(val) {
      this.isKnow = !this.isKnow;
    },
    showContent() {
      Dialog.alert({
        message: this.xy.content,
        confirmButtonColor: "#2d71d7",
        confirmButtonText: this.$t("main.confIrm")
      });
    }
  }
};
</script>

<style scoped>
.out {
  box-sizing: border-box;
  /* background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;

  background: #fff;
  padding: 0.06rem 0.2rem;
  align-items: center;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.Title {
  font-size: 0.16rem;
  position: relative;
}

.Chinese {
  color: #1b6a21;

  font-size: 0.18rem;
}

.right-icon {
  /* width: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
  width: 0.65rem;
}

.cc-wc {
  font-size: 0.1rem;
  color: #e9630a;
}

.body {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.list_item {
  color: #343434;
  font-size: 0.15rem;
  margin-bottom: 0.12rem;
}

.flex {
  display: flex;
  line-height: 0.2rem;
}

.list_input {
  width: 1.9rem;
  height: 0.33rem;

  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 0 0 0.01rem #a0a0a0;

  font-size: 0.14rem;
}

.list_input input {
  border: 0;
  width: 1.9rem;
  height: 0.33rem;
  box-sizing: border-box;
  padding: 0 0.1rem;
  color: #9e9e9e;
  font-size: 0.14rem;
  border-radius: 6px;
  line-height: 0.33rem;
}

.list_input div {
  border: 0;
  width: 1.9rem;
  height: 0.35rem;
  box-sizing: border-box;
  padding: 0 0.1rem;
  color: #9e9e9e;
  font-size: 0.14rem;
  line-height: 0.35rem;
}

.check_box {
  position: relative;
  align-items: center;
  margin-bottom: 0.08rem;
}

.check_box > input {
  visibility: hidden;

  width: 0.19rem;
  height: 0.19rem;
  border-radius: 50%;
  border: none;
  /* box-shadow: 0 0 0 1px #c1c1c1; */
  margin-right: 0.125rem;
}

.check_box label::before {
  display: inline-block;
  content: "";
  width: 0.2rem;
  height: 0.2rem;
  left: 0rem;
  top: 0.095rem;
  position: absolute;
  background: #ffebcc;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #737272;
}

.check_box input:checked + label::before {
  display: inline-block;
  content: "";
  width: 0.2rem;
  height: 0.2rem;
  background: #ffebcc;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #737272, inset 0 0 0 0.025rem #ffebcc,
    inset 0 0 0 0.1rem #601925;
  left: 0rem;
  top: 0.075rem;
  position: absolute;
}

.check_box input:checked + .list_input {
  /* box-shadow: 0 0 0 0.01rem #3662EC; */
}

.agree-box1 input {
  display: none;
}

.agree-box1 {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.2rem;
}

.agree-box1 label::before {
  display: inline-block;
  content: "";
  width: 0.175rem;
  height: 0.175rem;
  background-color: #ffffff;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
}

.agree-box1 input:checked + label::before {
  display: inline-block;
  content: "";
  width: 0.175rem;
  height: 0.175rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: url(../assets/img/rz-select-iocn.png) no-repeat center;
  background-size: cover;
}

.agree-box1 label {
  font-size: 0.12rem;
  margin-left: 0.325rem;
}

.upload {
  position: absolute;
  bottom: 0.33rem;
  width: 3.16rem;
  height: 0.5rem;
  background: url(../assets/img/view-more.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.18rem;
  color: #333;
  margin-left: 0.14rem;
}

.pop {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.pop_mask {
  background: url(../assets/img/kz_pop.png) no-repeat;
  background-size: 100% 100%;
}

.blue_xy {
  color: #2d71d7;
  font-size: 0.12rem;
  line-height: 0.175rem;
}

.en_style .list_item.flex {
  flex-wrap: wrap;
}

.en_style .list_item.flex > div:nth-child(1) {
  margin-bottom: 0.12rem;
}

.zb-top {
  flex-shrink: 0;
  width: 100%;
  height: 1.45rem;
  background: url(../assets/img/zhuanranF.png) no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.15rem;
}

.zb-top img {
  width: 0.94rem;
  height: 0.94rem;
  margin-right: 0.2rem;
}

.zb-top .info {
  display: flex;
  flex-direction: column;
  height: 0.94rem;
  justify-content: space-between;
}

.zb-edit {
  flex: 1;
  font-size: 0.13rem;
  background: url(../assets/img/zy_b_bg.png) no-repeat;
  border-radius: 0.08rem 0.08rem 0 0;
  background-size: 100% 100%;
  background-position: center bottom;
  background-attachment: fixed;
  padding: 0.15rem;
  box-sizing: border-box;
  padding-bottom: 0.84rem;
  position: relative;
}

.zb_title {
  display: flex;
  align-items: center;
  margin-top: 0.18rem;
  font-size: 0.175rem;
  color: #343434;
  font-weight: bold;
  margin-bottom: 0.13rem;
}

.zb_title img {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.15rem;
}

.zb_text {
  font-size: 0.15rem;
  color: #707070;
}
.zb_input {
  margin-top: 0.18rem;
  width: 100%;
  height: 0.4rem;
  background: #fff;
  border-radius: 0.05rem;
  box-shadow: inset 0 0 0 1px #929292;
}
.zb_input input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-radius: 0.05rem;
  padding: 0 0.15rem;
}

.zb_main {
  margin-top: 0.15rem;
}

.check_box:nth-child(1) .list_input {
  background: transparent;
  box-shadow: none;
  color: #929292;
}

.zb_tips {
  font-size: 0.12rem;
  color: #929292;
}
.zb_item {
  display: flex;
  align-items: center;
  color: #5f5f5f;
  font-size: 0.15rem;
}
.zb_item div:nth-child(3) {
  color: #171717;
}
.zb_item img {
  width: 0.15rem;
  height: 0.13rem;
  margin-right: 0.1rem;
}
.pic {
  width: 100%;
  height: 3rem;
}
.pic img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.no-data {
  background: url(../assets/img/no-data-prompt.png) no-repeat;
  background-size: 100% 100%;
  width: 2.6rem;
  height: 1.45rem;
}
.info-data {
  background: url(../assets/img/data_scan_bg.png) no-repeat;
  background-size: 100% 100%;
  width: 3rem;
  height: 3.35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zb_item > div:nth-child(3) {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zb_item > div:nth-child(4).goPar {
  flex: none;
  width: 0.39rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  font-size: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a72d28;
  color: #fff;
  margin-right: 0.15rem;
  cursor: pointer;
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-data > div:nth-child(1) {
  height: 0.88rem;
  width: 2.27rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data > div:nth-child(2) {
  width: 1.5rem;
  height: 0.38rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8d9ae;
  color: #333;
  border-radius: 0.08rem;
}
.info-data > div:nth-child(1) {
  margin-top: 0.2rem;
  font-size: 0.18rem;
}
.info-data > div:nth-child(2) {
  width: 2.3rem;
  margin-top: 0.21rem;
}
.info-data > div:nth-child(3) {
  width: 2.3rem;
  margin-top: 0.13rem;
  display: flex;
  align-items: center;
}
.info-data > div:nth-child(3) > div:nth-child(1) {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #fff;
  margin-right: 0.11rem;
}
.info-data > div:nth-child(3) > div:nth-child(1).active {
  box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 0.04rem #8d1829,
    inset 0 0 0 0.1rem #fff;
}
.info-data > div:nth-child(4) {
  width: 2.3rem;
  margin-top: 0.13rem;
  display: flex;
  align-items: center;
}
.info-data > div:nth-child(4) > div:nth-child(1) {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #fff;
  margin-right: 0.11rem;
}
.info-data > div:nth-child(4) > div:nth-child(1).active {
  box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 0.04rem #8d1829,
    inset 0 0 0 0.1rem #fff;
}
.info-data > div:nth-child(4) > input {
  width: 1.5rem;
  height: 0.32rem;
  border-radius: 0.05rem;
  border: none;
  box-sizing: border-box;
  padding: 0 0.12rem;
  font-size: 0.12rem;
  color: #333;
}
.info-data > div:nth-child(5) {
  width: 2.3rem;
  margin-top: 0.14rem;
  font-size: 0.12rem;
}
.info-data > div:nth-child(6) {
  margin-top: 0.25rem;
  width: 2rem;
  height: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../assets/img/view-more.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  color: #333;
  font-size: 0.14rem;
}
.isSuccess{
  color: #8d1829;
}
</style>